<template>
  <v-card flat>
    <v-card-title>
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        dense
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        @click="createServico()"
        align="center"
        color="secondary"
        class="white--text"
      >
        adicionar
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="servicos"
        :items-per-page="10"
        :sort-desc="true"
        class="data-tables data-tables__row-click"
        @click:row="goToServico"
      >
        <template v-slot:item.valor="{ item }">
          {{ item.valor ? "R$ " + item.valor : "" }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status | status }}
        </template>
      </v-data-table>
    </v-card-text>
    <NovoPrestado
      v-if="dialogServico"
      :dialogServico.sync="dialogServico"
      :item="servico"
      :add="add"
      @fetch-servicos="getServicos"
    />
  </v-card>
  <!-- @fetch-servico="getServicos" -->
</template>

<script>
import { fetchServicos } from "@/api/gestor/servicos.js";
export default {
  name: "ListaPrestados",

  components: {
    NovoPrestado: () => import("./NovoPrestado.vue"),
  },

  data() {
    return {
      search: "",
      servicos: [],
      servico: {},
      loading: true,
      dialogServico: false,
      add: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Serviço",
          value: "descricao",
        },
        {
          text: "Grupo",
          value: "grupo.descricao",
        },
        {
          text: "Unidade Padrão",
          value: "unidade",
        },
        {
          text: "Valor Padrão",
          value: "valor",
        },
        {
          text: "Status",
          value: "status",
        },
      ];
    },
  },

  methods: {
    createServico() {
      this.servico = {};
      this.dialogServico = true;
      this.add = true;
    },
    goToServico(item) {
      this.servico = { ...item };
      this.dialogServico = true;
      this.add = false;
    },

    getServicos() {
      this.loading = true;
      fetchServicos()
        .then((response) => {
          this.servicos = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getServicos();
  },
};
</script>

<style lang="scss"></style>
