import api from "../axios_service.js";

export async function fetchServicos(filtros) {
  const response = await api.get(`/gestor/servicos${filtros ? filtros : ""}`);
  return response.data.result;
}

// Busca um servico
export async function fetchServico(id) {
  const response = await api.get(`/gestor/servicos/${id}`);
  return response.data.result;
}

export function putServico(id, servico) {
  let body = {};
  for (let key in servico) {
    body[key] = servico[key];
  }
  return api.put(`/gestor/servicos/${id}`, body);
}

export function postServico(servico) {
  let body = {};
  for (let key in servico) {
    body[key] = servico[key];
  }
  return api.post("/gestor/servicos/add", body);
}
